/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('datatables.net');
require('datatables.net-bs4');
require('bootstrap-confirmation2');
require('select2');
require('bootstrap-slider');
require('bs-custom-file-input');
